import React from "react"
import Helmet from "react-helmet"
import SEO from "../components/seo";
import Layout from "../components/layout"
import CloudProviders from "../components/global/cloud-providers"
import GetStarted from "../components/global/get-started"

import bgLearnMore from "../images/home/background-learn-more.png"
import imgBenefits from "../images/home/chart-breakdown-min.png"

export default function Home() {
  return (
    <Layout>
      <SEO 
        title="Compute Software: Cloud Cost Optimization & Management" 
        description="See how Compute Software's Platform for Cloud Cost Optimization delivers the deepest optimizations and greatest cloud cost savings. Get the most actionable, highest value recommendations so you can focus on what matters most."
      />
      <Helmet></Helmet>

      <section className="home-hero jumbotron">
        <div className="container">
          <h1>Deep Cloud Cost Optimization</h1>
          <p>Lower your cloud costs more than any other way.</p>
          <p><a className="btn btn-lg" href="/request-demo" role="button">Request a Demo</a></p>
        </div>
      </section>

      <section className="home-testimonial">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <p><span className="trusted-by">Trusted by</span> Leading Cloud Adopters from <span className="fortune-100">the Fortune 100 to Venture Capital-Backed Companies</span></p>
            </div>
          </div>
        </div>
      </section>

      <section className="home-overview">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <p>An excess of cloud provider choices, unpredictable workloads, large amounts of data, and competing business objectives lead to suboptimal cloud usage and spend.</p>
              <p>Native cloud and other third party tools attempt to eliminate wasteful spending through cost allocation, anomaly detection, budgets, and rudimentary optimizations, but that only takes you so far.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="home-learn-more">
        <div className="container">
          <div className="row">
            <div className="content col-lg-8">
              <p>Compute Software is the cloud cost optimization solution that delivers the deepest cost savings. Better <span>data</span>, better <span>algorithms</span>, and better <span>workflow</span>.</p>
              <p><a className="learn-more" href="/platform" role="button">Learn More &#10230;</a></p>
            </div>
            <div className="col-lg-4">
              <img src={bgLearnMore} alt="Learn More Background" width="520" height="300" />
            </div>
          </div>
        </div>
      </section>

      <section className="home-benefits">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3>Maximize cost savings, performance, and engineering productivity, all at once.</h3>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="list-group-flush">
                <div className="list-group-item">
                  <div className="row">
                  <div className="icon-wrapper col-lg-1 col-md-1 col-sm-2 col-xs-2">
                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g>
                        <path d="M24.8379 16.0537C24.3949 12.4392 21.5422 9.5937 17.9266 9.15987C17.6153 9.12434 17.3022 9.10654 16.9889 9.10648C13.7935 9.11139 10.9164 11.0425 9.70132 13.9978C8.48622 16.953 9.17293 20.3495 11.4407 22.6006C12.6466 23.7554 13.3373 25.3469 13.357 27.0166V27.9285H20.6427V27.0119C20.6482 25.3738 21.3146 23.8074 22.4908 22.6673C24.2675 20.9532 25.1364 18.5046 24.8378 16.054V16.0537H24.8379Z" fill="#25282B"/>
                        <path d="M17 34C17.7717 33.9991 18.4593 33.5129 18.7174 32.7857H15.2827C15.5408 33.5129 16.2284 33.9991 17 34Z" fill="#25282B"/>
                        <path d="M13.9644 30.9643C13.9648 31.2995 14.2364 31.571 14.5715 31.5715H19.4287C19.7638 31.571 20.0353 31.2995 20.0358 30.9643V29.1429H13.9644V30.9643Z" fill="#25282B"/>
                        <path d="M16.9999 5.4643C17.6706 5.4643 18.2142 4.92064 18.2142 4.25V1.2143C18.2142 0.543668 17.6706 0 16.9999 0C16.3293 0 15.7856 0.543668 15.7856 1.2143V4.25C15.7856 4.92064 16.3293 5.4643 16.9999 5.4643Z" fill="#25282B"/>
                        <path d="M6.75212 8.46945C7.23264 8.92839 7.989 8.92839 8.46952 8.46945C8.69729 8.24175 8.82526 7.93282 8.82526 7.61075C8.82526 7.28868 8.69729 6.97976 8.46952 6.75205L6.3228 4.60574C6.09516 4.37796 5.78631 4.25 5.4643 4.25C5.1423 4.25 4.83345 4.37796 4.60574 4.60574C4.37796 4.83338 4.25 5.14223 4.25 5.4643C4.25 5.78638 4.37796 6.09516 4.60574 6.32287L6.75212 8.46945Z" fill="#25282B"/>
                        <path d="M5.4643 17C5.4643 16.3294 4.92064 15.7857 4.25 15.7857H1.2143C0.543934 15.7864 0.000664063 16.3296 0 17C0.000664063 17.6704 0.543934 18.2136 1.2143 18.2143H4.25C4.92064 18.2143 5.4643 17.6706 5.4643 17Z" fill="#25282B"/>
                        <path d="M7.92604 25.2144C7.50636 25.1022 7.05878 25.2228 6.75218 25.5306L4.60586 27.6772C4.37809 27.9048 4.25012 28.2137 4.25012 28.5358C4.25012 28.8578 4.37809 29.1666 4.60586 29.3943C5.08624 29.8533 5.84255 29.8533 6.32293 29.3943L8.46958 27.248C8.77731 26.9414 8.8979 26.4938 8.78574 26.0742C8.67358 25.6545 8.34573 25.3266 7.92604 25.2144Z" fill="#25282B"/>
                        <path d="M27.2479 25.5305C26.9413 25.2228 26.4937 25.1022 26.0741 25.2144C25.6544 25.3265 25.3266 25.6543 25.2144 26.074C25.1023 26.4937 25.2228 26.9413 25.5306 27.2479L27.6772 29.3942C28.1576 29.8531 28.9139 29.8531 29.3943 29.3942C29.6221 29.1665 29.75 28.8577 29.75 28.5356C29.75 28.2136 29.6221 27.9048 29.3943 27.6771L27.2479 25.5305Z" fill="#25282B"/>
                        <path d="M32.7858 15.7857H29.7501C29.0794 15.7857 28.5358 16.3294 28.5358 17C28.5358 17.6706 29.0794 18.2143 29.7501 18.2143H32.7858C33.4564 18.2143 34.0001 17.6706 34.0001 17C34.0001 16.3294 33.4564 15.7857 32.7858 15.7857Z" fill="#25282B"/>
                        <path d="M27.2479 8.46946L29.3942 6.32281C29.7009 6.01608 29.8207 5.56903 29.7084 5.15001C29.5961 4.73098 29.2689 4.40373 28.8499 4.29144C28.4308 4.17915 27.9838 4.29895 27.6771 4.60568L25.5304 6.75199C25.3026 6.9797 25.1747 7.28862 25.1747 7.61069C25.1747 7.93276 25.3026 8.24168 25.5304 8.46939C26.011 8.92839 26.7674 8.92839 27.2479 8.46946Z" fill="#25282B"/>
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect width="34" height="34" fill="white"/>
                        </clipPath>
                      </defs>
                    </svg>
                  </div> 
                  <div className="text-wrapper col-lg-11 col-md-11 col-sm-10 col-xs-10">
                    <h4>Understand Business Value</h4>
                    <p>Metrics and actions based not just on cloud provider costs, but also performance costs and engineering costs. Know exactly when and where cloud cost savings tradeoff with performance.</p>
                  </div>
                  </div>
                </div>
                <div className="list-group-item">
                <div className="row">
                  <div className="icon-wrapper col-lg-1 col-md-1 col-sm-2 col-xs-2">
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M23.3333 23.3333H20V16.6667H23.3333V23.3333ZM16.6667 23.3333H13.3333V6.66667H16.6667V23.3333ZM10 23.3333H6.66667V11.6667H10V23.3333ZM26.6667 0H3.33333C1.48333 0 0 1.48333 0 3.33333V26.6667C0 27.5507 0.351189 28.3986 0.976311 29.0237C1.60143 29.6488 2.44928 30 3.33333 30H26.6667C27.5507 30 28.3986 29.6488 29.0237 29.0237C29.6488 28.3986 30 27.5507 30 26.6667V3.33333C30 1.48333 28.5 0 26.6667 0Z" fill="#25282B"/>
                    </svg>
                  </div> 
                  <div className="text-wrapper col-lg-11 col-md-11 col-sm-10 col-xs-10">
                    <h4>Deepest Cost Savings</h4>
                    <p>Apply business objectives, workload characteristics, and all types of discounts for the deepest optimizations and greatest cost savings.</p>
                  </div>
                  </div>
                </div>
                <div className="list-group-item">
                <div className="row">
                  <div className="icon-wrapper col-lg-1 col-md-1 col-sm-2 col-xs-2">
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g>
                        <path d="M29.3849 0.0034728C23.5287 -0.101527 16.8499 2.95972 12.6224 7.70847C8.58245 7.78472 4.6487 9.44098 1.75245 12.3372C1.5837 12.5035 1.5237 12.7522 1.59745 12.9772C1.67245 13.2035 1.86745 13.3672 2.10245 13.401L6.91995 14.091L6.32495 14.7572C6.1037 15.0047 6.11495 15.381 6.34995 15.616L14.3837 23.6497C14.5049 23.771 14.6649 23.8322 14.8262 23.8322C14.9749 23.8322 15.1237 23.7797 15.2424 23.6735L15.9087 23.0785L16.5987 27.896C16.6325 28.131 16.8174 28.3035 17.0412 28.3785C17.0987 28.3972 17.1587 28.406 17.2199 28.406C17.3974 28.406 17.5787 28.3285 17.7037 28.2047C20.5599 25.3485 22.2162 21.4147 22.2924 17.3747C27.0462 13.1385 30.1324 6.46222 29.9962 0.613473C29.9874 0.280973 29.7187 0.0122228 29.3849 0.0034728ZM23.6249 10.7935C23.0162 11.4022 22.2162 11.7072 21.4149 11.7072C20.6137 11.7072 19.8137 11.4022 19.205 10.7935C17.9874 9.57473 17.9874 7.59222 19.205 6.37347C20.4237 5.15472 22.4062 5.15472 23.6249 6.37347C24.8437 7.59222 24.8437 9.57598 23.6249 10.7935Z" fill="#25282B"/>
                        <path d="M3.40495 21.131C2.06745 22.4685 0.2312 28.511 0.0261997 29.1947C-0.0400503 29.4147 0.0211997 29.6535 0.18245 29.816C0.30245 29.936 0.4612 29.9997 0.62495 29.9997C0.68495 29.9997 0.74495 29.991 0.80495 29.9735C1.4887 29.7685 7.5312 27.9322 8.8687 26.5947C10.3749 25.0885 10.3749 22.6372 8.8687 21.131C7.3612 19.6247 4.9112 19.626 3.40495 21.131Z" fill="#25282B"/>
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect width="30" height="30" fill="white" transform="translate(0 -0.000244141)"/>
                        </clipPath>
                      </defs>
                    </svg>
                    </div> 
                  <div className="text-wrapper col-lg-11 col-md-11 col-sm-10 col-xs-10">
                    <h4>Prioritized Actions</h4>
                    <p>Get the most actionable and highest value recommendations so you can focus on what matters most.</p>
                  </div>
                  </div>
                </div>
                <div className="list-group-item">
                <div className="row">
                  <div className="icon-wrapper col-lg-1 col-md-1 col-sm-2 col-xs-2">
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g>
                        <path d="M11.25 12.5C14.7018 12.5 17.5 9.70178 17.5 6.25C17.5 2.79822 14.7018 0 11.25 0C7.79822 0 5 2.79822 5 6.25C5 9.70178 7.79822 12.5 11.25 12.5Z" fill="#25282B"/>
                        <path d="M14.4175 26C13.7663 24.8725 13.8963 23.4838 14.6713 22.5C13.8963 21.5163 13.7663 20.1287 14.4175 19L15.4288 17.25C15.985 16.2863 17.0225 15.6875 18.1363 15.6875C18.2888 15.6875 18.4388 15.6988 18.5875 15.72C18.6288 15.6175 18.6813 15.5212 18.7325 15.4237C18.0575 15.1575 17.3288 15 16.5625 15H5.9375C2.66375 15 0 17.6638 0 20.9375V25.3125C0 25.83 0.42 26.25 0.9375 26.25H14.5613L14.4175 26Z" fill="#25282B"/>
                        <path d="M27.1287 23.0863C27.1537 22.8925 27.1875 22.7 27.1875 22.5C27.1875 22.2988 27.1525 22.1075 27.1287 21.9138L28.255 21.0613C28.5062 20.8713 28.5762 20.5238 28.4187 20.25L27.4075 18.5C27.25 18.2275 26.9137 18.1138 26.6237 18.2363L25.3275 18.7825C25.0112 18.5413 24.675 18.3287 24.3037 18.1737L24.1312 16.7975C24.0912 16.485 23.8262 16.25 23.5112 16.25H21.49C21.175 16.25 20.9087 16.485 20.87 16.7975L20.6975 18.1737C20.3262 18.3287 19.9887 18.5425 19.6737 18.7825L18.3775 18.2363C18.0875 18.1138 17.7512 18.2263 17.5937 18.5L16.5825 20.25C16.425 20.5225 16.495 20.87 16.7462 21.0613L17.8725 21.9138C17.8475 22.1075 17.8137 22.3 17.8137 22.5C17.8137 22.7013 17.8487 22.8925 17.8725 23.0863L16.7462 23.9388C16.495 24.1288 16.425 24.4763 16.5825 24.75L17.5937 26.5013C17.7512 26.7738 18.0875 26.8875 18.3775 26.765L19.6737 26.2175C19.99 26.4588 20.3262 26.6713 20.6975 26.8263L20.87 28.2025C20.9087 28.515 21.1737 28.75 21.4887 28.75H23.51C23.825 28.75 24.0912 28.515 24.13 28.2025L24.3025 26.8263C24.6737 26.6713 25.0112 26.4575 25.3262 26.2175L26.6225 26.7638C26.9125 26.8863 27.2487 26.7738 27.4062 26.5L28.4175 24.7488C28.575 24.4763 28.505 24.1288 28.2537 23.9375L27.1287 23.0863ZM22.5 25C21.1187 25 20 23.8813 20 22.5C20 21.1188 21.1187 20 22.5 20C23.8812 20 25 21.1188 25 22.5C25 23.8813 23.8812 25 22.5 25Z" fill="#25282B"/>
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect width="30" height="30" fill="white"/>
                        </clipPath>
                      </defs>
                    </svg>
                  </div> 
                  <div className="text-wrapper col-lg-11 col-md-11 col-sm-10 col-xs-10">
                    <h4>Continuous Improvement</h4>
                    <p>Easily track and measure the impact of cloud optimization actions across teams.</p>
                  </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <img src={imgBenefits} alt="Benefits Charts" loading="lazy" width="690" height="421"/>
            </div>
          </div>
        </div>
      </section>

      <CloudProviders />

      <GetStarted />

    </Layout>
  )
}
